import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "line-bot-container" }
const _hoisted_2 = { class: "usage-section" }
const _hoisted_3 = { class: "usage-content" }
const _hoisted_4 = { class: "usage-image" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "usage-section" }
const _hoisted_7 = { class: "usage-content" }
const _hoisted_8 = { class: "usage-image" }
const _hoisted_9 = ["src"]

import guideImage from '@/assets/pictures/reflectionbot_guide.webp';
import guide_restartImage from '@/assets/pictures/reflectionbot_restart.webp';

const qrCodeUrl = 'https://qr-official.line.me/gs/M_961womtf_GW.png?oat_content=qr'; // QRコード画像のURL
const lineBotUrl = 'https://lin.ee/WHg1zxB'; // ラインボットのURL

export default /*@__PURE__*/_defineComponent({
  __name: 'ReflectionAIPage',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("h1", null, "振り返りさん", -1)),
    _cache[4] || (_cache[4] = _createElementVNode("h2", null, "メモや感想よりも、質の高い「振り返り」で自分の考えを整理したり、深めたりしましょう！", -1)),
    _cache[5] || (_cache[5] = _createElementVNode("p", null, "以下のQRコードをスキャンして、友達追加してください。", -1)),
    _createElementVNode("div", { class: "qr-code" }, [
      _createElementVNode("img", {
        src: qrCodeUrl,
        alt: "LINE Bot QR Code",
        loading: "lazy"
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: _unref(guideImage),
            alt: "Guide to Using Reflection Bot",
            loading: "lazy"
          }, null, 8, _hoisted_5)
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("ul", { class: "usage-steps" }, [
          _createElementVNode("li", null, [
            _createElementVNode("p", null, "ステップ1"),
            _createElementVNode("p", null, "ラインボットを追加後、「振り返り」と入力します。")
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("p", null, "ステップ2"),
            _createElementVNode("p", null, "①事実 ②気付き ③教訓 によって、効果的に振り返りをします。")
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("p", null, "ステップ3"),
            _createElementVNode("p", null, "あなたの振り返りを文章でまとめます。")
          ])
        ], -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("img", {
            src: _unref(guide_restartImage),
            alt: "Restart Reflection Process Guide",
            loading: "lazy"
          }, null, 8, _hoisted_9)
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("ul", { class: "usage-steps" }, [
          _createElementVNode("li", null, [
            _createElementVNode("p", null, "※「振り返り」と入力すると、①からやり直すことができます。")
          ])
        ], -1))
      ])
    ]),
    _cache[6] || (_cache[6] = _createElementVNode("p", null, "以下のボタンをクリックして直接追加することもできます。", -1)),
    _createElementVNode("a", {
      href: lineBotUrl,
      target: "_blank",
      "aria-label": "友だち追加のリンク"
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("img", {
        src: "https://scdn.line-apps.com/n/line_add_friends/btn/ja.png",
        alt: "友だち追加",
        height: "36",
        loading: "lazy"
      }, null, -1)
    ]))
  ]))
}
}

})