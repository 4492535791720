import http from "@/service/httpclient";

class FileHandler {
    // ファイルのアップロードメソッド
    async uploadFile(file: File): Promise<void> {
        const formData = new FormData();
        formData.append('file', file, file.name);

        try {
            const uploadUrl = '/upload';

            // Content-Type を明示的に指定
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            };

            await http.post(uploadUrl, formData, config);
        } catch (error: any) {
            console.error('ファイルのアップロードエラー:', error);
            // 追加: 具体的なエラー情報をコンソールに出力
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
            } else if (error.request) {
                console.error('Request data:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
            throw error;
        }
    }

    // docsおよびpdfファイルのテキストを抽出するメソッド
    async extractText(file: File): Promise<string> {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const extractTextUrl = '/extract_text';

            const response = await http.post(extractTextUrl, formData);
            return response.data.text;
        } catch (error: any) {
            console.error('テキスト抽出エラー:', error);
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
            } else if (error.request) {
                console.error('Request data:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
            throw error;
        }
    }

    // テキストを要約するメソッド
    async getAnswer(file: File): Promise<string> {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const summarizeUrl = '/summarize';

            const response = await http.post(summarizeUrl, formData);
            return response.data.output_text;
        } catch (error: any) {
            console.error('回答取得エラー:', error);
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
            } else if (error.request) {
                console.error('Request data:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
            throw error;
        }
    }

    // タイトルを取得するメソッド
    async getTitle(file: File): Promise<string> {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const generateUrl = '/generate'; // 適切なエンドポイントに修正

            // Content-Type を明示的に指定
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            };

            const response = await http.post(generateUrl, formData, config);
            return response.data.result;
        } catch (error: any) {
            console.error('タイトル取得エラー:', error);
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
            } else if (error.request) {
                console.error('Request data:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
            throw error;
        }
    }

    /*
    // ウェブページからテキストを抽出し要約するメソッド
    async webSummarize(urls: string[]): Promise<string> {
        try {
            const summarizeUrl = '/web_summarize';

            const response = await http.post(summarizeUrl, { urls });
            return response.data.summary;
        } catch (error: any) {
            console.error('ウェブ要約取得エラー:', error);
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
            } else if (error.request) {
                console.error('Request data:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
            throw error;
        }
    }
    */
}

export default FileHandler;



