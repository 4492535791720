import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/pictures/yurutools_icon.webp'
import _imports_1 from '@/assets/pictures/745_profile.webp'


import { ref, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'Main_NavBar',
  setup(__props) {

const isNavbarFixed = ref(false);
const router = useRouter();

// スクロール時の処理
const handleScroll = () => {
    const scrollPosition = window.scrollY;
    // ここで適切なスクロール位置を設定してください
    const scrollThreshold = 0;
    isNavbarFixed.value = scrollPosition > scrollThreshold;
    };

const goToHome = () => {
    router.push('/');
    };

const goToAbout = () => {
    router.push('/about');
    };

const goToProfile = () => {
    router.push('/profile');
};

    onMounted(() => {
    // スクロールイベントを追加
    window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
    // コンポーネントがアンマウントされる際にイベントを削除
    window.removeEventListener('scroll', handleScroll);
    });


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(["navbar", { 'fixed': isNavbarFixed.value }])
  }, [
    _createElementVNode("div", { class: "container" }, [
      _createElementVNode("div", {
        class: "logo-container",
        onClick: goToHome
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "Logo",
          class: "logo-image"
        }, null, -1),
        _createElementVNode("div", { class: "company-name" }, "ゆるTools", -1)
      ])),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "nav-links" }, "無料でシンプルなAIお助けツール", -1)),
      _createElementVNode("div", { class: "media-icons" }, [
        _createElementVNode("img", {
          src: _imports_1,
          alt: "プロフィール画像",
          class: "profile-icons",
          onClick: goToProfile
        }),
        _cache[1] || (_cache[1] = _createElementVNode("a", {
          href: "https://www.buymeacoffee.com/yurutools",
          target: "_blank"
        }, [
          _createElementVNode("img", {
            src: "https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png",
            alt: "Buy Me A Coffee",
            class: "coffee-button"
          })
        ], -1)),
        _createElementVNode("button", {
          class: "about-button",
          onClick: goToAbout
        }, "About")
      ])
    ])
  ], 2))
}
}

})