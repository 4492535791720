import http from "@/service/httpclient";

export interface PdfMetadata {
    filename: string;
    url: string;
}

class PdfScrapeHandler {
    private async handleRequestError(error: any): Promise<void> {
        console.error('PDFの処理エラー:', error);
        if (error.response) {
            console.error('Response data:', error.response.data);
            console.error('Response status:', error.response.status);
            console.error('Response headers:', error.response.headers);
        } else if (error.request) {
            console.error('Request data:', error.request);
        } else {
            console.error('Error message:', error.message);
        }
        throw error;
    }

    // PDFのメタデータを取得するメソッド
    async fetchPdfMetadata(url: string): Promise<PdfMetadata[] | undefined> {
        try {
            const scrapeUrl = '/fetch-metadata/';
            const response = await http.post<{ pdf_files: PdfMetadata[] }>(scrapeUrl, { url });
            return response.data.pdf_files;
        } catch (error: any) {
            await this.handleRequestError(error);
        }
    }

    // PDFをダウンロードするメソッド
    async downloadPdfs(url: string): Promise<void> {
        try {
            const response = await http.post('/download-pdfs/', { url }, { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'application/zip' });

            // 現在の日付と時間を取得してファイル名に追加
            const now = new Date().toISOString().replace(/[:.]/g, '_');
            const filename = `pdfs_${now}.zip`;

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
            URL.revokeObjectURL(link.href);  // メモリリークを防ぐ
        } catch (error: any) {
            await this.handleRequestError(error);
        }
    }
}

export default PdfScrapeHandler;
