<template>
    <footer class="footer">
        <div class="link_conteiner">
            <a href="/terms" class="link">利用規約</a>
            <span class="link-separator"></span>
            <a href="/privacy" class="link">プライバシーポリシー</a>
            <span class="link-separator"></span>
            <a href="/contact" class="link">お問い合わせ</a>
        </div>
        <div class="footer_top">
            <p class="text-muted">
                © 2024 ゆるTools
                <span class="link-separator"></span>
                App icon by
                <a href="https://icons8.jp/" class="link">icons8</a>    
            </p>
        </div>
    </footer>
</template>

<style>
.footer {
    max-width: 75%;
    padding: 10px 0;
    /*margin-bottom: 20px;*/
    text-align: center;
    border-top: 1px solid
}

.link_container {
    margin-top: 10px;
}

.link {
    color: #333; 
    text-decoration: none; 
    cursor: pointer;
    transition: color 0.3s;
}

.link:hover {
    color: rgb(0, 132, 255); /* ホバー時のテキストカラーを指定 */
}

.link-separator {
    margin: 0 15px; /* リンク間のセパレータの余白を調整 */
}

/*-------------------------------------------
レスポンシブデザイン
-------------------------------------------*/
@media (max-width: 768px) {
    .link_conteiner {
        font-size: 0.8rem;
        white-space: nowrap;
    }
    .text-muted {
        font-size: 0.8rem;
    }

    .link-separator {
        margin: 0 6px; /* リンク間のセパレータの余白を調整 */
    }
}

</style>