import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "sentiment" }
const _hoisted_2 = { class: "form-item large-input" }
const _hoisted_3 = { class: "form-footer" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 0,
  class: "loading"
}
const _hoisted_6 = { class: "form-item" }
const _hoisted_7 = {
  class: "readonly-text",
  placeholder: "こちらに文章を入力してください。"
}
const _hoisted_8 = {
  key: 1,
  class: "error-message",
  role: "alert",
  "aria-live": "assertive"
}
const _hoisted_9 = {
  class: "alert",
  role: "alert"
}
const _hoisted_10 = {
  id: "abouts",
  class: "about"
}
const _hoisted_11 = { class: "about-container" }
const _hoisted_12 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "main-title" }, "感情分析くん", -1)),
    _createElementVNode("form", {
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.analyze && _ctx.analyze(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createElementVNode("label", {
          for: "input",
          class: "form-label"
        }, "テストしたい文", -1)),
        _withDirectives(_createElementVNode("textarea", {
          id: "input",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.input) = $event)),
          autocomplete: "off",
          placeholder: "こちらに文章を入力してください。",
          style: {"font-size":"1.2rem"}
        }, null, 512), [
          [_vModelText, _ctx.form.input]
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          type: "submit",
          disabled: !_ctx.form.input
        }, "解析", 8, _hoisted_4)
      ]),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[3] || (_cache[3] = [
            _createElementVNode("span", {
              class: "loading-spinner",
              "aria-live": "polite",
              role: "status"
            }, null, -1),
            _createElementVNode("p", null, "分析中", -1)
          ])))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        _cache[4] || (_cache[4] = _createElementVNode("label", { class: "form-label" }, "↓分析結果が表示されます↓", -1)),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.form.output), 1)
      ]),
      (_ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.errorMessage), 1)
          ]))
        : _createCommentVNode("", true)
    ], 32),
    _createElementVNode("section", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "project",
            key: index
          }, [
            _createElementVNode("img", {
              src: require(`@/assets/pictures/${project.image}`),
              alt: project.title
            }, null, 8, _hoisted_12),
            _createElementVNode("h3", null, _toDisplayString(project.title), 1),
            _createElementVNode("p", null, _toDisplayString(project.description), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}