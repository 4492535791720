import { onMounted, onBeforeUnmount } from 'vue';

export const useNinjaAdMax = (admaxWrapperAbove: any, admaxWrapperBelow?: any, admaxIdAbove?: string, admaxIdBelow?: string) => {
    const loadNinjaAds = (wrapper: any, id: string) => {
        if (wrapper.value) {
        // AdMaxの広告用divを生成
        const adDiv = document.createElement('div');
        adDiv.className = 'admax-switch';
        adDiv.setAttribute('data-admax-id', id);
        adDiv.style.display = 'inline-block';
        wrapper.value.appendChild(adDiv);

        // AdMaxスクリプトを動的に追加
        const script1 = document.createElement('script');
        script1.type = 'text/javascript';
        script1.text = `(admaxads = window.admaxads || []).push({admax_id: "${id}", type: "switch"});`;

        const script2 = document.createElement('script');
        script2.type = 'text/javascript';
        script2.charset = 'utf-8';
        script2.src = 'https://adm.shinobi.jp/st/t.js';
        script2.async = true;

        document.body.appendChild(script1);
        document.body.appendChild(script2);
        }
    };

    const loadAds = () => {
        // メインのAdMax広告をロード
        if (admaxIdAbove) {
        loadNinjaAds(admaxWrapperAbove, admaxIdAbove);
        }

        // ナビゲーション下のAdMax広告が指定されている場合はロード
        if (admaxWrapperBelow && admaxIdBelow) {
        loadNinjaAds(admaxWrapperBelow, admaxIdBelow);
        }
    };

    onMounted(() => {
        loadAds();
    });

    onBeforeUnmount(() => {
        if (admaxWrapperAbove.value) {
        admaxWrapperAbove.value.innerHTML = ''; // メインのAdMax広告をクリア
        }
        if (admaxWrapperBelow && admaxWrapperBelow.value) {
        admaxWrapperBelow.value.innerHTML = ''; // ナビゲーション下のAdMax広告をクリア
        }
    });
};
