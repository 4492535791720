import http from "@/service/httpclient";

class DataApiService {
  getAll(): Promise<any> {
    return http.get("/api/item");
  }

  create(input: string, output: string): Promise<any> {
    return http.post("/api/item", { input, output });
  }

}

export default new DataApiService();
