import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  id: "works",
  class: "works"
}
const _hoisted_2 = { class: "work-container" }
const _hoisted_3 = ["onClick", "aria-label"]
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = ["aria-label"]

import { useRouter } from 'vue-router';
import image1 from '@/assets/pictures/1417.webp';
import image2 from '@/assets/pictures/955.webp';
import image3 from '@/assets/pictures/1632.webp';
import image4 from '@/assets/pictures/319.webp';
import image5 from '@/assets/pictures/1451.webp';
import image6 from '@/assets/pictures/891.webp';



export default /*@__PURE__*/_defineComponent({
  __name: 'WorksPage',
  setup(__props) {

const router = useRouter();

const works = [
    {
        id: 1,
        image: image1,
        title: '振り返りさん',
        link: '/reflection'
    },
    {
        id: 2,
        image: image2,
        title: '音声文字起こし',
        link: '/transcript'
    },
    {
        id: 3,
        image: image3,
        title: 'PDF一括DL',
        link: '/pdfdownloader'
    },
    {
        id: 4,
        image: image4,
        title: '文章抽出・要約',
        link: '/summarizer'
    },
    {
        id: 5,
        image: image5,
        title: '感情分析',
        link: '/sentiment'
    },
    {
        id: 6,
        image: image6,
        title: 'タイトル提案',
        link: '/generator',
    }
];

const goToLink = (link: any) => {
    router.push(link);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(works, (work, index) => {
        return _createElementVNode("div", {
          class: "work",
          key: index,
          onClick: ($event: any) => (goToLink(work.link)),
          role: "button",
          "aria-label": work.title
        }, [
          _createElementVNode("img", {
            src: work.image,
            alt: work.title,
            loading: "lazy"
          }, null, 8, _hoisted_4),
          _createElementVNode("h3", null, _toDisplayString(work.title), 1),
          _createElementVNode("button", {
            class: "btn",
            "aria-label": 'View more about ' + work.title
          }, "View More", 8, _hoisted_5)
        ], 8, _hoisted_3)
      }), 64))
    ])
  ]))
}
}

})