import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "titlegenerator" }
const _hoisted_2 = {
  class: "file-container",
  "aria-labelledby": "file-upload-label"
}
const _hoisted_3 = { class: "button-container" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "loading" }
const _hoisted_6 = {
  key: 0,
  class: "loading-spinner",
  "aria-live": "polite",
  role: "status"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "response-container"
}
const _hoisted_9 = ["onUpdate:modelValue"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 1,
  class: "error-message",
  "aria-live": "assertive"
}
const _hoisted_12 = {
  class: "alert",
  role: "alert"
}
const _hoisted_13 = {
  id: "abouts",
  class: "about"
}
const _hoisted_14 = { class: "about-container" }
const _hoisted_15 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("h1", { class: "main-title" }, "タイトル提案くん", -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("label", {
        for: "fileInput",
        class: "fileinput-text"
      }, " ドラッグ＆ドロップしてファイルをアップロード(.docx, .pdf) ", -1)),
      _createElementVNode("input", {
        id: "fileInput",
        class: "large-input",
        ref: "fileInput",
        type: "file",
        accept: ".pdf, .docx",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadFile && _ctx.uploadFile(...args)))
      }, null, 544)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.submitPrompt && _ctx.submitPrompt(...args))),
        disabled: !_ctx.fileUploaded,
        class: "response-button"
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("span", { class: "button-text" }, "タイトル取得", -1)
      ]), 8, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6))
        : _createCommentVNode("", true),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("p", _hoisted_7, "気長にお待ちください"))
        : _createCommentVNode("", true)
    ]),
    (_ctx.response)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _cache[4] || (_cache[4] = _createElementVNode("h2", null, "↓ タイトル案 ↓", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.response.split('\n'), (title, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "answer-container"
            }, [
              _withDirectives(_createElementVNode("textarea", {
                class: "large-input",
                type: "text",
                "onUpdate:modelValue": ($event: any) => ((_ctx.titles[index]) = $event),
                readonly: ""
              }, null, 8, _hoisted_9), [
                [_vModelText, _ctx.titles[index]]
              ]),
              _createElementVNode("button", {
                onClick: ($event: any) => (_ctx.copyToClipboard(_ctx.titles[index])),
                class: "copy-success"
              }, _toDisplayString(_ctx.copySuccess && _ctx.copiedIndex === index ? 'Copied!' : 'Copy'), 9, _hoisted_10)
            ]))
          }), 128)),
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "annotation-container" }, [
            _createElementVNode("p", null, "この回答は自動生成されたものであり、原文の完全性や正確性を保証するものではありません。")
          ], -1))
        ]))
      : _createCommentVNode("", true),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.errorMessage), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("section", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chat_projects, (chat_project, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "project",
            key: index
          }, [
            _createElementVNode("img", {
              src: require(`@/assets/pictures/${chat_project.image}`),
              alt: chat_project.title
            }, null, 8, _hoisted_15),
            _createElementVNode("h3", null, _toDisplayString(chat_project.title), 1),
            _createElementVNode("p", null, _toDisplayString(chat_project.description), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}