<template>
  <div class="pdf-scrape">
    <h1 class="main-title">
      PDF一括ダウンロードくん
    </h1>

    <!-- 吹き出しメッセージ -->
    <div class="info-bubble">
      <p>pdfが表示される直前のURLを入力してボタンを押してください。</p>
      <p>取得できない場合は、他のリンクを試してみてください。</p>
    </div>
    
    <div class="input-container">
      <input v-model="url" class="url-input" placeholder="https://" aria-label="PDF URL入力" />
      <button @click="fetchPdfMetadata" :disabled="!url" class="fetch-button">PDFデータを取得</button>
    </div>
    
    <!-- ローディング画面 -->
    <div v-if="isLoading" class="loading" role="alert" aria-live="assertive">
      <p class="loading-text">{{ loadingText }}</p>
    </div>

    <!-- Google AdSense広告 -->
    <div class="ad-container" v-if="pdfMetadata.length">
      <ins class="adsbygoogle"
            style="display:block"
            data-ad-client="ca-pub-4976359435956104"
            data-ad-slot="6265851093"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
    </div>

    <!-- メタデータが取得された場合にのみ表示 -->
    <div v-if="pdfMetadata.length && !message" class="metadata-container">
      <button @click="downloadPdfs" :disabled="!pdfMetadata.length" class="download-button">一括ダウンロード</button>
      <h2 class="metadata-title">PDF一覧:</h2>
      <ul class="metadata-list">
        <li v-for="file in pdfMetadata" :key="file.url" class="metadata-item">
          <div class="metadata-item-content">
            <span class="filename">{{ file.filename }}</span>
            -  <a :href="file.url" target="_blank" class="view-link">View</a>
          </div>
        </li>
      </ul>
      <button @click="downloadPdfs" :disabled="!pdfMetadata.length" class="download-button">一括ダウンロード</button>
    </div>

    <!-- エラーメッセージがある場合のみ表示 -->
    <div v-if="message && !pdfMetadata.length" class="error-message">
      <div class="alert" role="alert" aria-live="assertive">
        {{ message }}
      </div>
    </div>

    <section id="abouts" class="about">
        <div class="about-container">
            <div class="project" v-for="(scrape_project, index) in scrape_projects" :key="index">
                <img :src="require(`@/assets/pictures/${scrape_project.image}`)" :alt="scrape_project.title">
                <h3>{{ scrape_project.title }}</h3>
                <p>{{ scrape_project.description }}</p>
            </div>
        </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import PdfScrapeHandler, { PdfMetadata } from '@/service/PdfScrapeHandler';

export default defineComponent({
  setup() {
    const url = ref<string>('');
    const pdfMetadata = ref<PdfMetadata[]>([]);
    const message = ref<string | null>(null);
    const isLoading = ref<boolean>(false);
    const loadingText = ref<string>('');
    const pdfService = new PdfScrapeHandler();

    const fetchPdfMetadata = async () => {
      try {
        message.value = null;  // エラーメッセージをリセット
        isLoading.value = true;
        loadingText.value = 'データ取得中';  // ローディングテキストを設定
        pdfMetadata.value = await pdfService.fetchPdfMetadata(url.value) || [];
        if (pdfMetadata.value.length === 0) {
          message.value = 'PDFリンクが見つかりませんでした。';
        }
      } catch (error: any) {
        message.value = error.response?.data?.detail || 'メタデータ取得中にエラーが発生しました。';
      } finally {
        isLoading.value = false;
      }
    };

    const downloadPdfs = async () => {
      try {
        isLoading.value = true;
        loadingText.value = 'ダウンロード中';  // ローディングテキストを設定
        await pdfService.downloadPdfs(url.value);
      } catch (error: any) {
        message.value = error.response?.data?.detail || 'PDFダウンロード中にエラーが発生しました。';
      } finally {
        isLoading.value = false;
      }
    };

    const scrape_projects = ref([
        {
            id: 1,
            image: 'pdficon.webp',
            title: 'PDFだけを抽出',
            description: 'たくさんのpdfのあるURLから検索でき、ページ内のPDFファイルだけを表示します。'
        },
        {
            id: 2,
            image: 'folders.webp',
            title: 'まとめて入手する',
            description: '１回でたくさんの書類(pdf)をダウンロードすることができます。何度もクリックする必要がなくなります。'
        },
        {
            id: 3,
            image: "lockicon.webp",
            title: '正しく利用する',
            description: '取得が禁止されているサイトは、ダウンロードできないようにしています。'
        }    
    ]);

    return {
      url,
      pdfMetadata,
      message,
      isLoading,
      loadingText,
      fetchPdfMetadata,
      downloadPdfs,
      scrape_projects
    };
  }
});
</script>

<style scoped>
/*-------------------------------------------
ページタイトル
-------------------------------------------*/
.main-title {
  font-size: 3em; /* サイズを調整 */
  margin-bottom: 20px;
  text-align: center;
}

/*-------------------------------------------
吹き出しメッセージ
-------------------------------------------*/
.info-bubble {
  max-width: 80%;
  background-color: #004085;
  border: 1px solid #002752;
  border-radius: 5px;
  color: #fff;
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 1em;
}

/*-------------------------------------------
入力エリア
-------------------------------------------*/
.pdf-scrape {
  max-width: 90%; /* 幅を調整 */
  padding: 10px;
  text-align: center;
}

.input-container {
  margin-bottom: 20px;
  text-align: center;
}

.url-input {
  width: 70%; /* 幅を調整 */
  padding: 10px;
  font-size: 1em;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.fetch-button, .download-button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 5px;
}

.fetch-button {
  background-color: #28a745;
  color: #fff;
}

.fetch-button:hover {
  background-color: #218838;
}

.fetch-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.download-button {
  background-color: #007bff;
  color: #fff;
}

.download-button:hover {
  background-color: #0056b3;
}

/*-------------------------------------------
ローディング画面
-------------------------------------------*/
.loading {
  margin-top: 20px;
  text-align: center;
}

.loading-text {
  font-size: 20px; /* サイズを調整 */
  animation: loadingAnimation 2s linear infinite;
}

@keyframes loadingAnimation {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

/*-------------------------------------------
メタデータ表示
-------------------------------------------*/
.metadata-container {
  margin-top: 20px;
  text-align: center;
}

.metadata-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.metadata-list {
  list-style: none;
  padding: 0;
}

.metadata-item {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.metadata-item-content {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f8f9fa;
  max-width: 90%; /* 幅を調整 */
  text-align: left;
}

.filename {
  flex: 1;
  font-size: 1em;
  font-weight: bold;
}

.view-link {
  color: #007bff;
  text-decoration: none;
}

.view-link:hover {
  text-decoration: underline;
}

/*-------------------------------------------
エラーメッセージ
-------------------------------------------*/
.error-message {
  margin-top: 20px;
  text-align: center;
}

.alert {
  padding: 15px;
  border-radius: 5px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/*-------------------------------------------
Aboutセクションのスタイリング
-------------------------------------------*/
.about-container {
    margin-top: 100px;
    max-width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.project {
    flex: 0 0 calc(33.333% - 10px); /* 3つのプロジェクトを等間隔に配置 */
    text-align: center;
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.project img {
    max-width: 30%;
    height: auto;
    margin-bottom: 10px;
}

.project h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.project p {
    font-size: 14px;
}

/* セクション間の余白 */
.about:not(:last-child) {
    margin-bottom: 40px;
}

/*-------------------------------------------
レスポンシブデザイン
-------------------------------------------*/
@media screen and (max-width: 768px) {
    .main-title {
        font-size: 2em;
        white-space: nowrap; /* 1行に抑える */
    }

    .url-input{
        width: 90%;
    }

    .info-bubble {
        max-width: 75%;
        padding: 5px;
        font-size: 0.5rem;
        white-space: nowrap;
    }
  
    .filename {
        font-size: 0.7rem;
    }

    .metadata-item-content {
        padding: 5px;
    }
    
    .project {
        flex: 0 0 calc(50% - 10px);
    }
}


@media screen and (max-width: 480px) {
    .project {
        flex: 0 0 calc(100% - 10px);
    }
}
</style>
