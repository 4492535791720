<template>
    <div class="transcription">
        <h1 class="main-title">音声文字起こしくん</h1>
        
        <!-- タブメニュー -->
        <div class="tab-menu">
            <button :class="{ active: activeTab === 'audio' }" @click="activeTab = 'audio'">音声ファイル</button>
            <button :class="{ active: activeTab === 'youtube' }" @click="activeTab = 'youtube'">Youtube文字起こし</button>
        </div>
        
        <!-- タブコンテンツ -->
        <div v-if="activeTab === 'audio'" class="tab-content">
            <input type="file" @change="onFileChange" accept="audio/*" class="audio-file-input">
            <button @click="transcribeAudio" :disabled="!audioFile" class="convert-button" aria-label="音声ファイル変換ボタン">変換</button>
        </div>
        <div v-if="activeTab === 'youtube'" class="tab-content">
            <input type="text" v-model="youtubeUrl" placeholder="https://" class="youtube-url-input">
            <button @click="transcribeYoutube" :disabled="!youtubeUrl" class="convert-button" aria-label="Youtube文字起こし変換ボタン">変換</button>
        </div>
        
        <!-- ローディング画面 -->
        <div v-if="isLoading" class="loading">
            <p class="loading-text">{{ loadingText }}</p>
        </div>

        <!-- 変換結果 -->
        <div v-if="transcription" class="transcription-result">
            <h2>変換結果</h2>
            <p>{{ transcription }}</p>
                <div class="annotation-container">
                    <p>この文字起こしはにAIモデルによって生成されたものであり、原文の完全性や正確性を保証するものではありません。</p>
                </div>
                <button @click="copyTranscription" class="copy-button" aria-label="コピー">
                    {{ copySuccess ? 'コピーしました' : 'コピー' }}
                </button>
        </div>

        <!-- エラーメッセージ -->
        <div v-if="errorMessage" class="error-message" aria-live="polite">
            <div class="alert" role="alert">
                {{ errorMessage }}
            </div>
        </div>
    
        <section id="abouts" class="about">
            <div class="about-container">
                <div class="project" v-for="(transcript_project, index) in transcript_projects" :key="index">
                    <img :src="require(`@/assets/pictures/${transcript_project.image}`)" :alt="transcript_project.title">
                    <h3>{{ transcript_project.title }}</h3>
                    <p>{{ transcript_project.description }}</p>
                </div>
            </div>
        </section>
    
    </div>
</template>

<script lang="ts">
import TranscriptionHandler from "@/service/TranscriptionHandler";
import { defineComponent } from 'vue';

const transcript_projects = [
    {
        id: 1,
        image: 'audiofile.webp',
        title: '様々な形式に対応',
        description: '音声ファイルやYoutubeのURLから読み取ることができます。'
    },
    {
        id: 2,
        image: 'transcription.webp',
        title: '文字起こしをする',
        description: '音声ファイルをAIモデルが高速で処理し、文字を起こします。何度も録音する必要がなくなります。'
    },
    {
        id: 3,
        image: "secure_browser.webp",
        title: '安全に使う',
        description: 'あなたの音声ファイルやAIモデルを、インターネット上に表示させずに処理します。'
    }    
];

export default defineComponent({
    data() {
        return {
        activeTab: 'audio',
        audioFile: null as File | null,
        youtubeUrl: '',
        transcription: '',
        isLoading: false,
        loadingText: '',
        copySuccess: false,
        errorMessage: '' as string,
        transcript_projects
        };
    },
    methods: {
        onFileChange(event: Event) {
            const target = event.target as HTMLInputElement;
            if (target.files && target.files.length > 0) {
                this.audioFile = target.files[0];
            }
        },

        async transcribeAudio() {
            if (!this.audioFile) return;
        
            this.isLoading = true;

            const handler = new TranscriptionHandler();
            try {
            this.transcription = await handler.transcribeAudio(this.audioFile);
            } catch (error) {
                console.error('Error:', error);
                this.errorMessage = 'エラーが発生しました。もう一度更新してからお試しください。';
            } finally {
                this.isLoading = false;
            }
        },
        async transcribeYoutube() {
            if (!this.youtubeUrl) return;
        
            this.isLoading = true;

            const handler = new TranscriptionHandler();
            try {
            this.transcription = await handler.getTranscriptFromYoutube(this.youtubeUrl);
            } catch (error) {
                console.error('Error:', error);
                this.errorMessage = 'エラーが発生しました。もう一度更新してからお試しください。';
            } finally {
                this.isLoading = false;
            }
        },
            startTypingEffect() {
            // ローディング画面でのタイピングエフェクトを開始する
            const loadingText = '文字起こし中...';
            let index = 0;
            setInterval(() => {
                this.loadingText = loadingText.slice(0, index + 1);
                index++;
                if (index === loadingText.length) index = 0;
            }, 400); // タイピング速度の調整
        },
            copyTranscription() {
                const textarea = document.createElement('textarea');
                textarea.value = this.transcription;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand('copy');
                document.body.removeChild(textarea);
                this.showCopySuccess();
        },
            showCopySuccess() {
                this.copySuccess = true;
                setTimeout(() => {
                    this.copySuccess = false;
            }, 2000);
        },
    },
    mounted() {
        this.startTypingEffect(); // コンポーネントがマウントされたときにタイピングエフェクトを開始する
    },
});
</script>

<style scoped>
/*-------------------------------------------
各項目のスタイリング
-------------------------------------------*/
.main-title {
    font-size: 3em;
    margin-bottom: 20px;
}

.transcription {
    max-width: 70%;
    padding: 10px 10px;
    text-align: center;
}

.annotation-container {
    position: relative;
    padding: 20px;
    font-size: 10px;
}

.annotation-container p {
    text-align: center;
}


/*-------------------------------------------
タブメニュー
-------------------------------------------*/
.tab-menu {
    margin-bottom: 20px;
    font-size: 24px;
}

.tab-menu button {
    margin: 0 10px;
    padding: 10px;
    border: none;
    background-color: #999999;
    cursor: pointer;
    font-size: inherit; /* タブメニューの文字サイズを継承する */
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
}

.tab-menu button:hover {
    background-color: #ddd;
    border-radius: 5px;
}

.tab-menu button.active {
    background-color: #ddd;
    border-radius: 5px;
    border-bottom: 3px solid green;
}

.tab-content {
    margin-bottom: 20px;
}

.audio-file-input, .youtube-url-input {
    margin-top: 10px;
    width: 90%;
    padding: 10px;
    font-size: 18px;
}

/*-------------------------------------------
変換ボタン
-------------------------------------------*/
.convert-button {
    margin-top: 20px;
    padding: 15px 30px;
    border: none;
    background-color: #28a745;
    color: white;
    cursor: pointer;
    font-size: 18px;
    border-radius: 5px;
    transition: background-color 0.3s;
    }

.convert-button:hover {
    background-color: #218838;
}

.convert-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.loading {
    margin-top: 20px;
}

.loading-text {
    font-size: 24px;
    animation: loadingAnimation 2s linear infinite;
}

@keyframes loadingAnimation {
    0% {
        opacity: 0.2;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}

/*-------------------------------------------
出力結果
-------------------------------------------*/
.transcription-result {
    font-size: 18px;
    margin-top: 20px;
    position: relative;
    border: 1.5px solid #ddd;
    border-radius: 10px;
    padding: 10px;
}

.transcription-result p {
    text-align: left;
}

.copy-button {
    margin-top: 10px;
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.copy-button:hover {
    background-color: #0056b3;
}

/*-------------------------------------------
X広告
-------------------------------------------*/
.copy-button {
    background-color: #3d3d3d;
    color: white;
    border: none;
    font-size: 1.1rem;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
}

.copy-button:hover {
    background-color: #45a049;
}

/*-------------------------------------------
エラーハンドリング
-------------------------------------------*/
.error-message {
    margin-bottom: 10px;
    position: relative;
    margin:0px 5%;
}

.alert {
    padding: 15px;
    border-radius: 5px;
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

/*-------------------------------------------
Aboutセクションのスタイリング
-------------------------------------------*/
.about-container {
    margin-top: 100px;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.project {
    flex: 0 0 calc(33.333% - 10px); /* 3つのプロジェクトを等間隔に配置 */
    text-align: center;
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.project img {
    max-width: 30%;
    height: auto;
    margin-bottom: 10px;
}

.project h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.project p {
    font-size: 14px;
}

/* セクション間の余白 */
.works:not(:last-child),
.about:not(:last-child) {
    margin-bottom: 40px;
}

/*-------------------------------------------
レスポンシブデザイン
-------------------------------------------*/
@media screen and (max-width: 768px) {
    .main-title {
        font-size: 2em;
        white-space: nowrap; /* 1行に抑える */
        align-items: baseline;
    }

    .tab-menu {
        font-size: 0.9rem;
    }

    .tab-content {
        font-size: 0.9rem;
    }

    .audio-file-input, .youtube-url-input {
        width: 100%;
    }

    .convert-button {
        position: relative;
        font-size: 1em;
        white-space: nowrap; /* 1行に抑える */
    }

    .transcription-result {
        font-size: 12px;
    }

    .transcription-result .annotation-container{
        font-size: 8px;
    }

    .project {
        flex: 0 0 calc(50% - 10px);
    }
}

@media screen and (max-width: 480px) {
    .project {
        flex: 0 0 calc(100% - 10px);
    }
}

</style>
