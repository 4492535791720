import { onMounted, onBeforeUnmount, ref, watch } from 'vue';

export const useAdsManager = (adContainer: any, route: any) => {
    const loadAds = () => {
        if (adContainer.value) {
        // AdSense スクリプトを動的に追加
        const adScript = document.createElement('script');
        adScript.async = true;
        adScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4976359435956104';
        adScript.crossOrigin = 'anonymous';
        document.head.appendChild(adScript);

        // AdSense 広告要素を動的に挿入
        const adIns = document.createElement('ins');
        adIns.className = 'adsbygoogle';
        adIns.style.display = 'block';
        adIns.setAttribute('data-ad-client', 'ca-pub-4976359435956104');
        adIns.setAttribute('data-ad-slot', '6265851093');
        adIns.setAttribute('data-ad-format', 'auto');
        adIns.setAttribute('data-full-width-responsive', 'true');

        adContainer.value.appendChild(adIns);

        // AdSense 広告を読み込む
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
    };

    const clearAds = () => {
        if (adContainer.value) {
        adContainer.value.innerHTML = '';
        }
    };

    onMounted(() => {
        if (route.meta.showAds) {
        loadAds();
        }
    });

    watch(() => route.meta.showAds, (showAds) => {
        if (showAds) {
        loadAds();
        } else {
        clearAds();
        }
    });

    onBeforeUnmount(() => {
        clearAds();
    });
};
