import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomePage from '@/components/Views/HomePage.vue';
import SummarizerPage from '@/components/Section/SummarizerPage.vue'
import TitlegeneratePage from '@/components/Section/TitlegeneratePage.vue'
import SentimentPage from './components/Section/SentimentPage.vue'
import TranscriptPage from './components/Section/TranscriptPage.vue'
import PdfdownloaderPage from './components/Section/PdfDownloaderPage.vue'
import ReflectionAIPage from './components/Section/ReflectionAIPage.vue'
import AboutPage from '@/components/Section/AboutPage.vue';
import ProfilePage from '@/components/Section/ProfilePage.vue';
import ContactPage from '@/components/Section/ContactPage.vue';
import TermsPage from '@/components/Section/TermsPage.vue';
import PrivacyPolicy from '@/components/Section/PrivacyPolicy.vue';
import NotFound from '@/components/Views/Notfound.vue';

const routes: RouteRecordRaw[] = [
    { path: '/', component: HomePage, meta: {title: 'ゆるTools｜無料でシンプルなAIお助けツール', showAds: true }},
    { path: '/generator', name: 'generator', component: TitlegeneratePage, meta: {title: 'タイトル提案くん｜ゆるTools', showAds: true }},
    { path: '/summarizer', name: 'summarizer', component: SummarizerPage, meta: {title: '文書要約くん｜ゆるTools', showAds: true } },
    { path: '/sentiment', name: 'sentiment', component: SentimentPage, meta: {title: '感情分析くん｜ゆるTools', showAds: true } },
    { path: '/transcript', name: 'transcript', component: TranscriptPage, meta: {title: '文字起こしくん｜ゆるTools', showAds: true } },
    { path: '/pdfdownloader', name: 'pdfdownloader', component: PdfdownloaderPage, meta: {title: 'pdf一括ダウンロードくん｜ゆるTools', showAds: true } },
    { path: '/reflection', name: 'reflectionai', component: ReflectionAIPage, meta: {title: '振り返りさん｜ゆるTools', showAds: false } },    
    { path: '/about', name: 'about', component: AboutPage, meta: {title: 'About｜ゆるTools', showAds: false } },
    { path: '/profile', name: 'profile', component: ProfilePage, meta: {title: 'Profile｜ゆるTools', showAds: false } },
    { path: '/contact', name: 'contact', component: ContactPage, meta: {title: 'お問い合わせ・リクエスト｜ゆるTools', showAds: false } },
    { path: '/terms', name: 'terms', component: TermsPage, meta: {title: '利用規約｜ゆるTools', showAds: false } },
    { path: '/privacy', name: 'privacy', component: PrivacyPolicy, meta: {title: 'プライバシーポリシー｜ゆるTools', showAds: false } },
    {path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound, meta: { showAds: false }}
]

const router = createRouter({
    history: createWebHistory(), // HTML5 History モード
    routes,
})

export default router;

