<template>
    <div class="not-found">
        <h1>404 - Page Not Found</h1>
        <p>ご指定のページは見つかりませんでした。</p>
        <router-link class="home-link" to="/">ホームに戻る</router-link>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'NotFound'
});
</script>

<style scoped>
.not-found {
    text-align: center;
    margin-top: 50px;
    font-family: Arial, sans-serif; /* フォントの指定で可読性向上 */
}

.not-found h1 {
    color: #ff6f61; /* エラーページの見出しにアクセントカラー */
    font-size: 2rem;
}

.not-found p {
    color: #333; /* テキストの色を変更して視認性を向上 */
    font-size: 1.2rem;
}

.home-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff; /* ボタンの背景色 */
    color: white; /* ボタンの文字色 */
    text-decoration: none;
    border-radius: 5px;
}

.home-link:hover {
    background-color: #0056b3; /* ホバー時のボタンの背景色 */
}
</style>
