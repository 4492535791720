import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "summarizer" }
const _hoisted_2 = {
  class: "file-container",
  "aria-labelledby": "file-upload-label"
}
const _hoisted_3 = {
  key: 0,
  class: "button-container"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 1,
  class: "response-container"
}
const _hoisted_6 = { class: "text-header" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "button-container" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { class: "loading" }
const _hoisted_11 = {
  key: 0,
  class: "loading-spinner",
  "aria-live": "polite",
  role: "status"
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = {
  key: 2,
  class: "response-container"
}
const _hoisted_14 = {
  key: 3,
  class: "error-message",
  "aria-live": "assertive"
}
const _hoisted_15 = {
  class: "alert",
  role: "alert"
}
const _hoisted_16 = {
  id: "abouts",
  class: "about"
}
const _hoisted_17 = { class: "about-container" }
const _hoisted_18 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[10] || (_cache[10] = _createElementVNode("h1", { class: "main-title" }, "文章要約くん", -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("label", {
        id: "file-upload-label",
        class: "fileinput-text"
      }, " ドラッグ＆ドロップしてファイルをアップロード (.docx, .pdf) ", -1)),
      _createElementVNode("input", {
        ref: "fileInput",
        type: "file",
        accept: ".pdf, .docx",
        class: "large-input",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadFile && _ctx.uploadFile(...args)))
      }, null, 544)
    ]),
    (_ctx.fileUploaded && !_ctx.textExtracted)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.extractText && _ctx.extractText(...args))),
            disabled: _ctx.loading,
            class: "response-button extract-button"
          }, _cache[5] || (_cache[5] = [
            _createElementVNode("span", { class: "button-text" }, "テキスト抽出", -1)
          ]), 8, _hoisted_4)
        ]))
      : _createCommentVNode("", true),
    (_ctx.extractedText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[6] || (_cache[6] = _createElementVNode("h2", null, "抽出されたテキスト", -1)),
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.copyExtractedText && _ctx.copyExtractedText(...args))),
              disabled: _ctx.loading,
              class: "copy-text-button",
              "aria-label": "コピー"
            }, _toDisplayString(_ctx.copySuccess ? 'コピーしました' : 'テキストをコピー'), 9, _hoisted_7)
          ]),
          _createElementVNode("p", null, _toDisplayString(_ctx.extractedText), 1),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.submitPrompt && _ctx.submitPrompt(...args))),
              disabled: _ctx.loading,
              class: "response-button summarize-button"
            }, _cache[7] || (_cache[7] = [
              _createElementVNode("span", { class: "button-text" }, "要約", -1)
            ]), 8, _hoisted_9)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_10, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("span", _hoisted_11))
        : _createCommentVNode("", true),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("p", _hoisted_12, "要約中です。気長にお待ちください"))
        : _createCommentVNode("", true)
    ]),
    (_ctx.response)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _cache[8] || (_cache[8] = _createElementVNode("h2", null, "要約", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.response), 1),
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "annotation-container" }, [
            _createElementVNode("p", null, "この要約は自動生成されたものであり、原文の完全性や正確性を保証するものではありません。")
          ], -1))
        ]))
      : _createCommentVNode("", true),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.errorMessage), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("section", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chat_projects, (chat_project, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "project",
            key: index
          }, [
            _createElementVNode("img", {
              src: require(`@/assets/pictures/${chat_project.image}`),
              alt: chat_project.title
            }, null, 8, _hoisted_18),
            _createElementVNode("h3", null, _toDisplayString(chat_project.title), 1),
            _createElementVNode("p", null, _toDisplayString(chat_project.description), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}