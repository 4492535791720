<template>
    <section id="works" class="works">
        <div class="work-container">
            <div class="work" v-for="(work, index) in works" :key="index" @click="goToLink(work.link)" role="button" :aria-label="work.title">
                <img :src="work.image" :alt="work.title" loading="lazy">
                <h3>{{ work.title }}</h3>
                <button class="btn" :aria-label="'View more about ' + work.title">View More</button> 
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import image1 from '@/assets/pictures/1417.webp';
import image2 from '@/assets/pictures/955.webp';
import image3 from '@/assets/pictures/1632.webp';
import image4 from '@/assets/pictures/319.webp';
import image5 from '@/assets/pictures/1451.webp';
import image6 from '@/assets/pictures/891.webp';


const router = useRouter();

const works = [
    {
        id: 1,
        image: image1,
        title: '振り返りさん',
        link: '/reflection'
    },
    {
        id: 2,
        image: image2,
        title: '音声文字起こし',
        link: '/transcript'
    },
    {
        id: 3,
        image: image3,
        title: 'PDF一括DL',
        link: '/pdfdownloader'
    },
    {
        id: 4,
        image: image4,
        title: '文章抽出・要約',
        link: '/summarizer'
    },
    {
        id: 5,
        image: image5,
        title: '感情分析',
        link: '/sentiment'
    },
    {
        id: 6,
        image: image6,
        title: 'タイトル提案',
        link: '/generator',
    }
];

const goToLink = (link: any) => {
    router.push(link);
};
</script>

<style scoped>
/*-------------------------------------------
各項目のスタイリング
-------------------------------------------*/
.works {
    max-width: 100%;
    padding: 20px 15%;
    font-family: 'Helvetica', sans-serif;
}

.work-container {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.work {
    flex: 0 0 calc(16.666% - 10px);
    text-align: center;
    /*margin-bottom: 30px;*/
    max-width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.5s, opacity 0.5s;
    background-color: #fff;
}

.work img {
    width: 100%;
    height: auto;
    max-height: 200px;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    border-radius: 5px;
}

.work h3 {
    font-size: 18px;
    margin-bottom: 5px;
    white-space: nowrap; /* 1行に抑える */
}

.work p {
    font-size: 14px;
    margin-bottom: 10px;
    color: #333;
}

.work:hover {
    transform: scale(0.98);
    opacity: 0.8;
}

.work .btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.work .btn:hover {
    background-color: #0056b3;
}

/*-------------------------------------------
レスポンシブデザインの設定
-------------------------------------------*/
@media screen and (max-width: 1200px) {
    .work {
        flex: 1 1 calc(33.333% - 10px); /* 3列に調整 */
    }
}

@media (max-width: 600px) {
    .work {
        flex: 1 1 calc(50% - 20px); /* 2列に調整 */
    }
}
</style>