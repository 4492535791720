import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router';
import './assets/base.css';

const app = createApp(App)

router.beforeEach((to, from, next) => {
    document.title = (to.meta && to.meta.title) ? String(to.meta.title) : '業務や教育支援に役立つAIツールコレクション｜T-tools';
    next();
    });

app.use(router)

app.mount('#app')
