import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = {
  ref: "admaxWrapperAbove",
  class: "admax-wrapper",
  role: "complementary",
  "aria-label": "上部の忍者AdMax広告"
}
const _hoisted_3 = {
  key: 0,
  ref: "adContainer",
  class: "ad-container",
  role: "complementary",
  "aria-label": "広告"
}
const _hoisted_4 = {
  ref: "admaxWrapperBelow",
  class: "admax-wrapper",
  role: "complementary",
  "aria-label": "下部の忍者AdMax広告"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Main_NavBar = _resolveComponent("Main_NavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FootBar = _resolveComponent("FootBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Main_NavBar),
    _createElementVNode("div", _hoisted_2, null, 512),
    _createVNode(_component_router_view),
    (_ctx.$route.meta.showAds)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, null, 512))
      : _createCommentVNode("", true),
    _createVNode(_component_FootBar),
    _createElementVNode("div", _hoisted_4, null, 512)
  ]))
}