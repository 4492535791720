import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/pictures/x.webp'


const _hoisted_1 = { class: "profile-section" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "profile-content" }
const _hoisted_4 = { class: "profile-info" }
const _hoisted_5 = { class: "profile-name" }
const _hoisted_6 = { class: "profile-description" }
const _hoisted_7 = { class: "social-icons" }
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "profile-image-wrapper" }
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.name), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.description), 1),
          _createElementVNode("div", _hoisted_7, [
            _cache[1] || (_cache[1] = _createElementVNode("a", {
              href: "https://x.com/Xiljo8MNKQDKUTB",
              target: "_blank",
              "aria-label": "X",
              class: "social-icon",
              rel: "noopener noreferrer"
            }, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "X Icon",
                class: "social-icon-image"
              })
            ], -1)),
            _createElementVNode("a", {
              href: _ctx.lineBotUrl,
              target: "_blank",
              class: "linebot-icon",
              rel: "noopener noreferrer"
            }, _cache[0] || (_cache[0] = [
              _createElementVNode("img", {
                src: "https://scdn.line-apps.com/n/line_add_friends/btn/ja.png",
                alt: "友だち追加",
                height: "36"
              }, null, -1)
            ]), 8, _hoisted_8)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("img", {
            src: _ctx.profileImage,
            alt: "Profile Image",
            class: "profile-image"
          }, null, 8, _hoisted_10)
        ])
      ])
    ])
  ]))
}