<template>
  <div id ="app">
      <Main_NavBar/>
      <div ref="admaxWrapperAbove" class="admax-wrapper" role="complementary" aria-label="上部の忍者AdMax広告"></div>
      <router-view></router-view>
      <div v-if="$route.meta.showAds" ref="adContainer" class="ad-container" role="complementary" aria-label="広告"></div>
      <!--AdSection /-->
      <FootBar />
      <div ref="admaxWrapperBelow" class="admax-wrapper" role="complementary" aria-label="下部の忍者AdMax広告"></div>
  </div>
</template>

<script lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useAdsManager } from '@/service/useAdsManager';
import { useNinjaAdMax } from '@/service/useNinjaAdMax';
import Main_NavBar from '@/components/Base/Main_NavBar.vue';
//import AdSection from '@/components/Base/AdSection.vue';
import FootBar from '@/components/Base/FootBar.vue';


export default {
  components: {
    Main_NavBar,
    //AdSection,
    FootBar,
  },
  setup() {
    const route = useRoute();
    const adContainer = ref<HTMLDivElement | null>(null);
    const admaxWrapperAbove = ref<HTMLDivElement | null>(null);
    const admaxWrapperBelow = ref<HTMLDivElement | null>(null);

    // Google Adsense管理のカスタムフック
    useAdsManager(adContainer, route);

    // admaxIdをそれぞれ設定
    const admaxIdAbove = '34728bd7cb585e667b4cabb1b21e8a2a';
    const admaxIdBelow = '8abc25ed816ad8ba7edca258ab0db20a';
    

    // 忍者AdMaxの広告管理のカスタムフック
    useNinjaAdMax(admaxWrapperAbove, admaxWrapperBelow, admaxIdAbove, admaxIdBelow);

    return {
      adContainer,
      admaxWrapperAbove,
      admaxWrapperBelow,
    };
  },
};
</script>

<style>
#app {
  font-family: 'Helvetica', sans-serif;
  color: #333;
  font-weight: normal;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: linear-gradient(135deg, #f5f5f5, #e0e0e0);
}

/* PC向けレイアウト */
@media (min-width: 769px) {
  #app {
    max-width: 1920px;
    min-width: 768px;
    margin: 0 auto;
    width: 100%;
  }

  .admax-wrapper {
    text-align: center;
  }
}

/* モバイル向けレイアウト */
@media (max-width: 768px) {
  #app {
    max-width: 100%;
  }
}

/* 広告コンテナのスタイル */
.ad-container {
  width: 100%;
  max-width: 728px;
  min-height: 90px;
  margin: 20px auto;
  text-align: center;
  display: block;
}

</style>