import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "pdf-scrape" }
const _hoisted_2 = { class: "input-container" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = {
  key: 0,
  class: "loading",
  role: "alert",
  "aria-live": "assertive"
}
const _hoisted_5 = { class: "loading-text" }
const _hoisted_6 = {
  key: 1,
  class: "ad-container"
}
const _hoisted_7 = {
  key: 2,
  class: "metadata-container"
}
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "metadata-list" }
const _hoisted_10 = { class: "metadata-item-content" }
const _hoisted_11 = { class: "filename" }
const _hoisted_12 = ["href"]
const _hoisted_13 = ["disabled"]
const _hoisted_14 = {
  key: 3,
  class: "error-message"
}
const _hoisted_15 = {
  class: "alert",
  role: "alert",
  "aria-live": "assertive"
}
const _hoisted_16 = {
  id: "abouts",
  class: "about"
}
const _hoisted_17 = { class: "about-container" }
const _hoisted_18 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[7] || (_cache[7] = _createElementVNode("h1", { class: "main-title" }, " PDF一括ダウンロードくん ", -1)),
    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "info-bubble" }, [
      _createElementVNode("p", null, "pdfが表示される直前のURLを入力してボタンを押してください。"),
      _createElementVNode("p", null, "取得できない場合は、他のリンクを試してみてください。")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.url) = $event)),
        class: "url-input",
        placeholder: "https://",
        "aria-label": "PDF URL入力"
      }, null, 512), [
        [_vModelText, _ctx.url]
      ]),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.fetchPdfMetadata && _ctx.fetchPdfMetadata(...args))),
        disabled: !_ctx.url,
        class: "fetch-button"
      }, "PDFデータを取得", 8, _hoisted_3)
    ]),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.loadingText), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.pdfMetadata.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[4] || (_cache[4] = [
          _createElementVNode("ins", {
            class: "adsbygoogle",
            style: {"display":"block"},
            "data-ad-client": "ca-pub-4976359435956104",
            "data-ad-slot": "6265851093",
            "data-ad-format": "auto",
            "data-full-width-responsive": "true"
          }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.pdfMetadata.length && !_ctx.message)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.downloadPdfs && _ctx.downloadPdfs(...args))),
            disabled: !_ctx.pdfMetadata.length,
            class: "download-button"
          }, "一括ダウンロード", 8, _hoisted_8),
          _cache[6] || (_cache[6] = _createElementVNode("h2", { class: "metadata-title" }, "PDF一覧:", -1)),
          _createElementVNode("ul", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pdfMetadata, (file) => {
              return (_openBlock(), _createElementBlock("li", {
                key: file.url,
                class: "metadata-item"
              }, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("span", _hoisted_11, _toDisplayString(file.filename), 1),
                  _cache[5] || (_cache[5] = _createTextVNode(" - ")),
                  _createElementVNode("a", {
                    href: file.url,
                    target: "_blank",
                    class: "view-link"
                  }, "View", 8, _hoisted_12)
                ])
              ]))
            }), 128))
          ]),
          _createElementVNode("button", {
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.downloadPdfs && _ctx.downloadPdfs(...args))),
            disabled: !_ctx.pdfMetadata.length,
            class: "download-button"
          }, "一括ダウンロード", 8, _hoisted_13)
        ]))
      : _createCommentVNode("", true),
    (_ctx.message && !_ctx.pdfMetadata.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.message), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("section", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scrape_projects, (scrape_project, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "project",
            key: index
          }, [
            _createElementVNode("img", {
              src: require(`@/assets/pictures/${scrape_project.image}`),
              alt: scrape_project.title
            }, null, 8, _hoisted_18),
            _createElementVNode("h3", null, _toDisplayString(scrape_project.title), 1),
            _createElementVNode("p", null, _toDisplayString(scrape_project.description), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}