import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "transcription" }
const _hoisted_2 = { class: "tab-menu" }
const _hoisted_3 = {
  key: 0,
  class: "tab-content"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 1,
  class: "tab-content"
}
const _hoisted_6 = ["disabled"]
const _hoisted_7 = {
  key: 2,
  class: "loading"
}
const _hoisted_8 = { class: "loading-text" }
const _hoisted_9 = {
  key: 3,
  class: "transcription-result"
}
const _hoisted_10 = {
  key: 4,
  class: "error-message",
  "aria-live": "polite"
}
const _hoisted_11 = {
  class: "alert",
  role: "alert"
}
const _hoisted_12 = {
  id: "abouts",
  class: "about"
}
const _hoisted_13 = { class: "about-container" }
const _hoisted_14 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[9] || (_cache[9] = _createElementVNode("h1", { class: "main-title" }, "音声文字起こしくん", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: _normalizeClass({ active: _ctx.activeTab === 'audio' }),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeTab = 'audio'))
      }, "音声ファイル", 2),
      _createElementVNode("button", {
        class: _normalizeClass({ active: _ctx.activeTab === 'youtube' }),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeTab = 'youtube'))
      }, "Youtube文字起こし", 2)
    ]),
    (_ctx.activeTab === 'audio')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("input", {
            type: "file",
            onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onFileChange && _ctx.onFileChange(...args))),
            accept: "audio/*",
            class: "audio-file-input"
          }, null, 32),
          _createElementVNode("button", {
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.transcribeAudio && _ctx.transcribeAudio(...args))),
            disabled: !_ctx.audioFile,
            class: "convert-button",
            "aria-label": "音声ファイル変換ボタン"
          }, "変換", 8, _hoisted_4)
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeTab === 'youtube')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.youtubeUrl) = $event)),
            placeholder: "https://",
            class: "youtube-url-input"
          }, null, 512), [
            [_vModelText, _ctx.youtubeUrl]
          ]),
          _createElementVNode("button", {
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.transcribeYoutube && _ctx.transcribeYoutube(...args))),
            disabled: !_ctx.youtubeUrl,
            class: "convert-button",
            "aria-label": "Youtube文字起こし変換ボタン"
          }, "変換", 8, _hoisted_6)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.loadingText), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.transcription)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _cache[7] || (_cache[7] = _createElementVNode("h2", null, "変換結果", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.transcription), 1),
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "annotation-container" }, [
            _createElementVNode("p", null, "この文字起こしはにAIモデルによって生成されたものであり、原文の完全性や正確性を保証するものではありません。")
          ], -1)),
          _createElementVNode("button", {
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.copyTranscription && _ctx.copyTranscription(...args))),
            class: "copy-button",
            "aria-label": "コピー"
          }, _toDisplayString(_ctx.copySuccess ? 'コピーしました' : 'コピー'), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.errorMessage), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("section", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.transcript_projects, (transcript_project, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "project",
            key: index
          }, [
            _createElementVNode("img", {
              src: require(`@/assets/pictures/${transcript_project.image}`),
              alt: transcript_project.title
            }, null, 8, _hoisted_14),
            _createElementVNode("h3", null, _toDisplayString(transcript_project.title), 1),
            _createElementVNode("p", null, _toDisplayString(transcript_project.description), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}