import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  id: "works",
  class: "works"
}
const _hoisted_2 = { class: "work-container" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = {
  id: "abouts",
  class: "about"
}
const _hoisted_6 = { class: "about-container" }
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = {
  id: "works",
  class: "works"
}
const _hoisted_9 = { class: "work-container" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["src", "alt"]
const _hoisted_12 = {
  id: "abouts",
  class: "about"
}
const _hoisted_13 = { class: "about-container" }
const _hoisted_14 = ["src", "alt"]
const _hoisted_15 = {
  id: "works",
  class: "works"
}
const _hoisted_16 = { class: "work-container" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["src", "alt"]
const _hoisted_19 = {
  id: "abouts",
  class: "about"
}
const _hoisted_20 = { class: "about-container" }
const _hoisted_21 = ["src", "alt"]
const _hoisted_22 = {
  id: "works",
  class: "works"
}
const _hoisted_23 = { class: "work-container" }
const _hoisted_24 = ["onClick"]
const _hoisted_25 = ["src", "alt"]
const _hoisted_26 = {
  id: "abouts",
  class: "about"
}
const _hoisted_27 = { class: "about-container" }
const _hoisted_28 = ["src", "alt"]

import { useRouter } from 'vue-router';

export default /*@__PURE__*/_defineComponent({
  __name: 'AboutPage',
  setup(__props) {

const router = useRouter();

const scrape_works = [
    {
        id: 1,
        image: "1632.webp",
        title: 'PDF一括DL',
        description: 'サイト内にある複数のPDFを一度でダウンロードします',
        link: '/pdfdownloader'
    }
];

const transcript_works = [
    {
        id: 1,
        image: "955.webp",
        title: '音声文字起こし',
        description: '音声ファイルやYoutubeの音声を文字起こしします。',
        link: '/transcript'
    }
];

const chat_works = [
    {
        id: 1,
        image: '891.webp',
        title: 'タイトル提案',
        description: 'アップロードされたファイルの文章からタイトルを提案します。',
        link: '/generator',
    },
    {
        id: 2,
        image: '319.webp',
        title: '文章要約',
        description: 'アップロードされたファイルの文章を抽出したり要約したります。',
        link: '/summarizer'
    },
];

const works = [
    {
        id: 1,
        image: '1451.webp',
        title: '感情分析',
        description: '文章を入力したら、ポジティブさやネガティブさを判定します。通知表や毎日の日記のお供にどうぞ。',
        link: '/sentiment'
    }
];

const scrape_projects = [
    {
        id: 1,
        image: 'pdficon.webp',
        title: 'PDFだけを抽出',
        description: 'たくさんのpdfのあるURLから検索でき、ページ内のPDFファイルだけを表示します。'
    },
    {
        id: 2,
        image: 'folders.webp',
        title: 'まとめて入手する',
        description: '１回でたくさんの書類(pdf)をダウンロードすることができます。何度もクリックする必要がなくなります。'
    },
    {
        id: 3,
        image: "lockicon.webp",
        title: '正しく利用する',
        description: '取得が禁止されているサイトは、ダウンロードできないようにしています。'
    }    
];

const transcript_projects = [
    {
        id: 1,
        image: 'audiofile.webp',
        title: '様々な形式に対応',
        description: '音声ファイルやYoutubeのURLから読み取ることができます。'
    },
    {
        id: 2,
        image: 'transcription.webp',
        title: '文字起こしをする',
        description: '音声ファイルをAIモデルが高速で処理し、文字を起こします。何度も録音する必要がなくなります。'
    },
    {
        id: 3,
        image: "secure_browser.webp",
        title: '安全に使う',
        description: 'あなたの音声ファイルやAIモデルを、インターネット上に表示させずに処理します。'
    }    
];

const chat_projects = [
    {
        id: 1,
        image: 'upload.webp',
        title: '文書ファイルに対応',
        description: 'Word等のdocx形式とpdf形式のファイルを読み取ることができます。'
    },
    {
        id: 2,
        image: 'summary.webp',
        title: '概要をつかむ',
        description: '重要な情報を識別し、要約を作成して、文書の要点を把握します。もうページをめくったりスクロールしたりする必要がありません。'
    },
    {
        id: 3,
        image: "user_security.webp",
        title: 'AIを安全に使う',
        description: 'Google社のGemmaモデルを使用し、あなたの文書ファイルをインターネット上に表示させずに処理します。'
    }    
];

const projects = [
    {
        id: 1,
        image: 'world_icon.webp',
        title: '多言語に対応',
        description: 'あらゆる言語の文章を受け入れ、あらゆる言語で感情を分析します。'
    },
    {
        id: 2,
        image: 'decision.webp',
        title: '感情を判定する',
        description: '「ポジティブ」「ニュートラル」「ネガティブ」が何％かを、客観的に判定します。'
    },
    {
        id: 3,
        image: "Analyze.webp",
        title: '感情分析に特化したAI',
        description: '感情分析モデル「lxyuan/distilbert-base-multilingual-cased-sentiments-student」を使います。'
    }  
];

const goToLink = (link: any) => {
    router.push(link);
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "catchphrase" }, "About", -1)),
    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "explain" }, "「ゆるTools」は、無料で使いやすいシンプルなAIツールアプリです。", -1)),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(scrape_works, (scrape_work, index) => {
          return _createElementVNode("div", {
            class: "work",
            key: index,
            onClick: ($event: any) => (goToLink(scrape_work.link))
          }, [
            _createElementVNode("img", {
              src: require(`@/assets/pictures/${scrape_work.image}`),
              alt: scrape_work.title
            }, null, 8, _hoisted_4),
            _createElementVNode("h3", null, _toDisplayString(scrape_work.title), 1),
            _createElementVNode("p", null, _toDisplayString(scrape_work.description), 1)
          ], 8, _hoisted_3)
        }), 64))
      ])
    ]),
    _createElementVNode("section", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(scrape_projects, (scrape_project, index) => {
          return _createElementVNode("div", {
            class: "project",
            key: index
          }, [
            _createElementVNode("img", {
              src: require(`@/assets/pictures/${scrape_project.image}`),
              alt: scrape_project.title
            }, null, 8, _hoisted_7),
            _createElementVNode("h3", null, _toDisplayString(scrape_project.title), 1),
            _createElementVNode("p", null, _toDisplayString(scrape_project.description), 1)
          ])
        }), 64))
      ])
    ]),
    _createElementVNode("section", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(transcript_works, (transcript_work, index) => {
          return _createElementVNode("div", {
            class: "work",
            key: index,
            onClick: ($event: any) => (goToLink(transcript_work.link))
          }, [
            _createElementVNode("img", {
              src: require(`@/assets/pictures/${transcript_work.image}`),
              alt: transcript_work.title
            }, null, 8, _hoisted_11),
            _createElementVNode("h3", null, _toDisplayString(transcript_work.title), 1),
            _createElementVNode("p", null, _toDisplayString(transcript_work.description), 1)
          ], 8, _hoisted_10)
        }), 64))
      ])
    ]),
    _createElementVNode("section", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(transcript_projects, (transcript_project, index) => {
          return _createElementVNode("div", {
            class: "project",
            key: index
          }, [
            _createElementVNode("img", {
              src: require(`@/assets/pictures/${transcript_project.image}`),
              alt: transcript_project.title
            }, null, 8, _hoisted_14),
            _createElementVNode("h3", null, _toDisplayString(transcript_project.title), 1),
            _createElementVNode("p", null, _toDisplayString(transcript_project.description), 1)
          ])
        }), 64))
      ])
    ]),
    _createElementVNode("section", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(works, (work, index) => {
          return _createElementVNode("div", {
            class: "work",
            key: index,
            onClick: ($event: any) => (goToLink(work.link))
          }, [
            _createElementVNode("img", {
              src: require(`@/assets/pictures/${work.image}`),
              alt: work.title
            }, null, 8, _hoisted_18),
            _createElementVNode("h3", null, _toDisplayString(work.title), 1),
            _createElementVNode("p", null, _toDisplayString(work.description), 1)
          ], 8, _hoisted_17)
        }), 64))
      ])
    ]),
    _createElementVNode("section", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(projects, (project, index) => {
          return _createElementVNode("div", {
            class: "project",
            key: index
          }, [
            _createElementVNode("img", {
              src: require(`@/assets/pictures/${project.image}`),
              alt: project.title
            }, null, 8, _hoisted_21),
            _createElementVNode("h3", null, _toDisplayString(project.title), 1),
            _createElementVNode("p", null, _toDisplayString(project.description), 1)
          ])
        }), 64))
      ])
    ]),
    _createElementVNode("section", _hoisted_22, [
      _createElementVNode("div", _hoisted_23, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(chat_works, (chat_work, index) => {
          return _createElementVNode("div", {
            class: "work",
            key: index,
            onClick: ($event: any) => (goToLink(chat_work.link))
          }, [
            _createElementVNode("img", {
              src: require(`@/assets/pictures/${chat_work.image}`),
              alt: chat_work.title
            }, null, 8, _hoisted_25),
            _createElementVNode("h3", null, _toDisplayString(chat_work.title), 1),
            _createElementVNode("p", null, _toDisplayString(chat_work.description), 1)
          ], 8, _hoisted_24)
        }), 64))
      ])
    ]),
    _createElementVNode("section", _hoisted_26, [
      _createElementVNode("div", _hoisted_27, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(chat_projects, (chat_project, index) => {
          return _createElementVNode("div", {
            class: "project",
            key: index
          }, [
            _createElementVNode("img", {
              src: require(`@/assets/pictures/${chat_project.image}`),
              alt: chat_project.title
            }, null, 8, _hoisted_28),
            _createElementVNode("h3", null, _toDisplayString(chat_project.title), 1),
            _createElementVNode("p", null, _toDisplayString(chat_project.description), 1)
          ])
        }), 64))
      ])
    ])
  ], 64))
}
}

})