<template>
  <div class="line-bot-container">
    <h1>振り返りさん</h1>
    <h2>メモや感想よりも、質の高い「振り返り」で自分の考えを整理したり、深めたりしましょう！</h2>
    <p>以下のQRコードをスキャンして、友達追加してください。</p>
    <div class="qr-code">
      <img :src="qrCodeUrl" alt="LINE Bot QR Code" loading="lazy" />
    </div>

    <div class="usage-section">
      <div class="usage-content">
        <div class="usage-image">
          <img :src="guideImage" alt="Guide to Using Reflection Bot" loading="lazy" />
        </div>
        <ul class="usage-steps">
          <li>
            <p>ステップ1</p>
            <p>ラインボットを追加後、「振り返り」と入力します。</p>
          </li>
          <li>
            <p>ステップ2</p>
            <p>①事実 ②気付き ③教訓 によって、効果的に振り返りをします。</p>
          </li>
          <li>
            <p>ステップ3</p>
            <p>あなたの振り返りを文章でまとめます。</p>
          </li>
        </ul>
      </div>
    </div>

    <div class="usage-section">
      <div class="usage-content">
        <div class="usage-image">
          <img :src="guide_restartImage" alt="Restart Reflection Process Guide" loading="lazy" />
        </div>
        <ul class="usage-steps">
          <li>
            <p>※「振り返り」と入力すると、①からやり直すことができます。</p>
          </li>
        </ul>
      </div>
    </div>

    <p>以下のボタンをクリックして直接追加することもできます。</p>
    <a :href="lineBotUrl" target="_blank"  aria-label="友だち追加のリンク">
      <img src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" height="36" loading="lazy">
    </a>
  
  </div>
</template>

<script setup lang="ts">
import guideImage from '@/assets/pictures/reflectionbot_guide.webp';
import guide_restartImage from '@/assets/pictures/reflectionbot_restart.webp';

const qrCodeUrl = 'https://qr-official.line.me/gs/M_961womtf_GW.png?oat_content=qr'; // QRコード画像のURL
const lineBotUrl = 'https://lin.ee/WHg1zxB'; // ラインボットのURL
</script>

<style scoped>
/*-------------------------------------------
各項目のスタイリング
-------------------------------------------*/
.line-bot-container {
  text-align: center;
  padding: 2rem;
}

.line-bot-container h1 {
  color: #333;
  margin-bottom: 20px;
  font-size: 3em;
}

.line-bot-container p {
  color: #555;
  margin: 2rem;
  font-size: 1.2rem;
}

.qr-code img {
  width: 200px;
  height: 200px;
  border: 2px solid #333; /* QRコードの境界線 */
}

/*-------------------------------------------
使い方セクション
-------------------------------------------*/
.usage-section {
  margin-top: 2rem;
  text-align: center;
}

.usage-section h2 {
  color: #333;
  margin-bottom: 1.5rem;
}

.usage-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem; /* 画像と文章の間にスペースを追加 */
}

.usage-image img {
  width: 400px;
  height: auto;
  border-radius: 8px;
  border: 2px solid #ddd;
}

.usage-steps {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left; /* ステップの文章を左揃え */
}

.usage-steps li {
  margin-bottom: 1.5rem;
}

.usage-steps p {
  color: #333;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: left;
  margin: 0;
  padding: 0.5rem 0;
  border-bottom: 2px solid #ddd;
  background-color: #f0f0f0;
  border-radius: 5px;
}

/*-------------------------------------------
レスポンシブデザイン
-------------------------------------------*/
@media screen and (max-width: 768px) {
  .line-bot-container h1 {
      font-size: 2em;
      white-space: nowrap; /* 1行に抑える */
      align-items: baseline;
  }

  .line-bot-container h2 {
      font-size: 0.9rem;
  }

  .line-bot-container p {
      font-size: 0.8rem;
  }

  .qr-code img {
      width: 100px;
      height: 100px;
  }

  .usage-section h2 {
      font-size: 0.9rem;
  }

  .usage-image img {
      width: 200px;
  }

  .usage-steps p {
    font-size: 0.7rem;
  }

}

</style>
