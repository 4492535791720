<template>
    <div class="privacy-policy">
        <h1 class="title">プライバシーポリシー</h1>
        <p class="right-text">
            制定日 : 2024年4月1日
        </p>
        <p class="text-separator"></p>
        <p>
            ゆるTools（以下、「当サイト」といいます。）は、本ウェブサイト上で提供するサービス（以下、「本サービス」といいます。）における、
            ユーザーの個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
        </p>

        <section>
            <h2 class="section-title">個人情報の取り扱いについて</h2>
                <p class="section-text">
                    当サイトでは、お客様から提供された個人情報を適切に管理し、サービス提供並びにお問い合わせへの対応の目的でのみ利用します。
                    また、法令に基づいて開示が必要な場合を除いて、個人情報を第三者に開示することはありません。
                </p>
        </section>

        <section>
            <h2 class="section-title">免責事項</h2>
                <p class="section-text">
                    当サイトの提供する情報は慎重に調査・検証されていますが、その正確性や適切性について一切の保証をいたしかねます。
                    また、生成AIモデルのテクノロジーに依存する当サイトのサービスを使用する場合、その結果生成される情報は、必ずしも完全性や正確性を保証するものではありません。
                    提供されるコンテンツはあくまで参考として使用され、ご自身の判断と責任に基づいてご利用ください。
                    当サイトからリンクやバナー等によって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。
                </p>
        </section>

        <section>
            <h2 class="section-title">データのセキュリティ</h2>
                <p class="section-text">
                    当サイトは、お客様の情報のセキュリティの確保に努めています。
                    ブラウザに保存されているすべてのデータは安全に保存、削除され、他人がアクセスすることはできません。
                    私たち自身はこのデータにアクセスすることはできません。
                </p>
        </section>

        <section>
            <h2 class="section-title">著作権について</h2>
                <p class="section-text">
                    当サイトで掲載している文章や画像などにつきましては、無断転載することを禁止します。
                    当ブログは著作権や肖像権の侵害を目的としたものではありません。
                    著作権や肖像権に関して問題がございましたら、
                    <a href="https://docs.google.com/forms/d/1T5UAAp0UvNGpbFR0HYIwbsg9EI9CB9pzABSMf7_4__Q/edit">お問い合わせフォーム</a>
                    よりご連絡ください。迅速に対応いたします。
                </p>
        </section>

        <section>
            <h2 class="section-title">掲載されている広告について</h2>
                <p class="section-text">
                    当サイトでは、第三者配信の広告サービス「Googleアドセンス」を利用しています。
                    このような広告配信事業者は、ユーザーの興味に応じた商品やサービスの広告を表示するため、
                    当サイトや他サイトへのアクセスに関する情報 『Cookie』（氏名、住所、メール アドレス、電話番号は含まれません）を使用することがあります。
                    またGoogleアドセンスに関して、このプロセスの詳細やこのような情報が広告配信事業者に使用されないようにする方法については
                    <a href="https://policies.google.com/technologies/ads?gl=jp">「ポリシーと規約」</a>
                    をご覧ください。
                </p>
        </section>

        <section>
            <h2 class="section-title">アクセスツール</h2>
                <p class="section-text">
                    当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
                    Googleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。
                    トラフィックデータは匿名で収集されており、個人を特定するものではありません。
                    この機能はCookieを無効にすることで収集を拒否することができますので、お使いのブラウザの設定をご確認ください。
                    本ポリシーに関して、詳しくは
                    <a href="https://marketingplatform.google.com/about/analytics/terms/jp/">｢Google アナリティクス利用規約｣</a>
                    をご覧ください。
                </p>
        </section>

        <section>
            <h2 class="section-title">リンクについて</h2>
                <p class="section-text">
                    当サイトは基本的にリンクフリーです。リンクを行う場合の許可や連絡は不要です。
                    ただし、インラインフレームの使用や画像の直リンクはご遠慮ください。
                </p>
        </section>

        <section>
            <h2 class="section-title">プライバシーポリシーの変更</h2>
                <p class="section-text">
                    当サイトは予告なくプライバシーポリシーを変更する場合があります。
                    変更がある場合は本ページに掲載されますので、定期的に確認いただきますようお願いいたします。
                </p>
        </section>

        <section>
            <h2 class="section-title">お問い合わせについて</h2>
                <p class="section-text">
                    プライバシーポリシーやお客様の情報の取り扱い方法に関するご質問やご意見がありましたら、
                    <a href="/contact">こちら</a>
                    までお気軽にお問い合わせください。
                </p>
                <p class="text-separator"></p>
                <p class="section-text">
                    当サイトのツールを使用すると、このプライバシーポリシーの条件に同意したことになります。
                </p>
        </section>

        <p class="text-separator"></p>
        <p class="right-text">
            以上
        </p>
    </div>
</template>


<style scoped>
.privacy-policy {
    font-family: 'Arial', sans-serif;
    max-width: 70%;
    margin: 0 auto;
    padding: 20px;
}

.title {
    font-size: 2em;
    text-align: center;
    margin-bottom: 20px;
}

.section-title {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
}

.section-text {
    line-height: 1.6;
}

.text-separator {
    margin: 20px;
}

.right-text{
    text-align: right;
}
</style>
