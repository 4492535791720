import type { AxiosInstance } from "axios";
import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
//axios.defaults.baseURL = 'http://localhost:8000';  // [dev] the FastAPI backend ※uvicornの標準ポート=127.0.0.1
//axios.defaults.baseURL = 'http://0.0.0.0:9090/';
axios.defaults.baseURL = 'https://yurutools-backend-hs62na4i5a-an.a.run.app/';  // [prod] the FastAPI_prod backend

const apiClient: AxiosInstance = axios.create({});

export default apiClient;