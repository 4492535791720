import http from "@/service/httpclient";

class TranscriptionHandler {
    async transcribeAudio(file: File): Promise<string> {
        const formData = new FormData();
        formData.append('file', file);

        try {
        const transcriptionUrl = '/transcribe';

        const response = await http.post(transcriptionUrl, formData);

        return response.data.transcription;
        } catch (error: any) {
        console.error('音声文字起こし取得エラー:', error);
        if (error.response) {
            console.error('Response data:', error.response.data);
            console.error('Response status:', error.response.status);
            console.error('Response headers:', error.response.headers);
        } else if (error.request) {
            console.error('Request data:', error.request);
        } else {
            console.error('Error message:', error.message);
        }
        throw error;
        }
    }

    async getTranscriptFromYoutube(youtubeUrl: string): Promise<string> {
        try {
            const response = await http.get(`/youtube_transcript?youtube_url=${encodeURIComponent(youtubeUrl)}`);
            return response.data.transcript;
        } catch (error: any) {
            console.error('音声文字起こし取得エラー:', error);
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
            } else if (error.request) {
                console.error('Request data:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
            throw error;
        }
    }
}


export default TranscriptionHandler;
