<template>
    <div class="titlegenerator">
        <h1 class="main-title">タイトル提案くん</h1>
    
        <!-- ファイルアップロード -->
        <div class="file-container" aria-labelledby="file-upload-label">
            <label for="fileInput" class="fileinput-text">
                ドラッグ＆ドロップしてファイルをアップロード(.docx, .pdf)
            </label>
            <input id="fileInput" class="large-input" ref="fileInput" type="file" accept=".pdf, .docx" @change="uploadFile" />
        </div>
    
        <!-- プロンプトに合わせて回答 -->
        <div class="button-container">
            <button @click="submitPrompt" :disabled="!fileUploaded" class="response-button">
                <span class="button-text">タイトル取得</span>
            </button>
        </div>

        <div class="loading">
            <span v-if="loading" class="loading-spinner" aria-live="polite" role="status"></span>
            <p v-if="loading">気長にお待ちください</p>
        </div>

        <div v-if="response" class="response-container">
            <h2>↓ タイトル案 ↓</h2>
            <div v-for="(title, index) in response.split('\n')" :key="index" class="answer-container">
                <textarea  class="large-input" type="text" v-model="titles[index]" readonly></textarea>
                <button @click="copyToClipboard(titles[index])" class="copy-success">
                    {{ copySuccess && copiedIndex === index ? 'Copied!' : 'Copy' }}
                </button>
            </div>
            <div class="annotation-container">
                <p>この回答は自動生成されたものであり、原文の完全性や正確性を保証するものではありません。</p>
            </div>
        </div>
        
        <!-- エラーメッセージ -->
        <div v-if="errorMessage" class="error-message" aria-live="assertive">
            <div class="alert" role="alert">
                {{ errorMessage }}
            </div>
        </div>

        <section id="abouts" class="about">
            <div class="about-container">
                <div class="project" v-for="(chat_project, index) in chat_projects" :key="index">
                    <img :src="require(`@/assets/pictures/${chat_project.image}`)" :alt="chat_project.title">
                    <h3>{{ chat_project.title }}</h3>
                    <p>{{ chat_project.description }}</p>
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
// eslint-disable-next-line
import FileHandler from '@/service/FileHandler';
import { defineComponent } from 'vue';

const chat_projects = [
    {
        id: 1,
        image: 'upload.webp',
        title: '文書ファイルに対応',
        description: 'Word等のdocx形式とpdf形式のファイルを読み取ることができます。'
    },
    {
        id: 2,
        image: 'summary.webp',
        title: '概要をつかむ',
        description: '重要な情報を識別し、要約を作成して、文書の要点を把握します。もうページをめくったりスクロールしたりする必要がありません。'
    },
    {
        id: 3,
        image: "user_security.webp",
        title: 'AIを安全に使う',
        description: 'Google社のGemmaモデルを使用し、あなたの文書ファイルをインターネット上に表示させずに処理します。'
    }    
];

export default defineComponent({
    data() {
        return {
        response: null as string | null,
        fileUploaded: false,
        loading: false,
        titles: [] as string[],
        copySuccess: false,
        copiedIndex: -1,
        errorMessage: '' as string,
        chat_projects
        };
    },
    methods: {
        // eslint-disable-next-line
        uploadFile(event: Event): void {
        const fileInput = this.$refs.fileInput as HTMLInputElement;
        const file = fileInput.files?.[0];
        if (file) {
            console.log('ファイルをアップロード:', file);
            this.fileUploaded = true;
            this.errorMessage = ''; // エラーメッセージをクリア
        } else {
            this.errorMessage = 'ファイルが選択されていません。';
            console.error('ファイルが選択されていません。');
        }
        },
        
        async submitPrompt(): Promise<void> {
            this.loading = true;

            try {
                const fileInput = this.$refs.fileInput as HTMLInputElement;
                const file = fileInput.files?.[0];
                if (file) {
                    const response = await this.getFileHandler().getTitle(file);
                    this.response = response;
                    this.titles = response.split('\n').filter(Boolean); // 空行を除外
                } else {
                    this.errorMessage = 'ファイルが選択されていません。';
                    console.error('ファイルが選択されていません。');
                }
            } catch (error) {
                this.errorMessage = 'エラーが発生しました。もう一度更新してからお試しください。';
            } finally {
                this.loading = false;
            }
        },
            getFileHandler(): FileHandler {
            return new FileHandler();
        },
            copyToClipboard(text: string): void {
                const textArea = document.createElement('textarea');
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand('copy');
                document.body.removeChild(textArea);
                // 任意のコピー成功メッセージをここで表示するか、他のフィードバックを追加できます。
                this.copySuccess = true;
                this.copiedIndex = this.titles.indexOf(text);
                setTimeout(() => {
                    this.copySuccess = false;
                    this.copiedIndex = -1;
                }, 2000);
        },
            showCopySuccess() {
                this.copySuccess = true;
                setTimeout(() => {
                    this.copySuccess = false;
            }, 2000);
        },
    },
});
</script>


<style scoped>

/*-------------------------------------------
各項目のスタイリング
-------------------------------------------*/
.main-title {
    font-size: 3em;
    margin-bottom: 20px;
}


.titlegenerator {
    max-width: 70%;
    padding: 10px 10px;
    text-align: center;
}

.file-container {
    font-size: 1.2rem;
    margin-bottom: 10px;
    position: relative;
    /*border: 1px solid #ddd;*/
    border-radius: 10px;
    padding: 10px;
}

.response-container {
    font-size: 1.2rem;
    margin-bottom: 20px;
    position: relative;
    border: 1.5px solid #ddd;
    border-radius: 10px;
    padding: 10px;
}

.response-container p {
    text-align: left;
}

.annotation-container {
    position: relative;
    padding: 20px;
}

.annotation-container p {
    text-align: center;
    font-size: 10px;
}

.button-container {
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
}

/*-------------------------------------------
アップロード画面
-------------------------------------------*/
.fileinput-text {
    margin-bottom: 1rem;
}

.large-input {
    font-size: 1.2rem;
    padding: 8px;
    margin: 0.5rem 0;
    width: 80%;
}

/*-------------------------------------------
ボタン
-------------------------------------------*/
.response-button {
    font-size: 18px;
    padding: 12px 24px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    transition: all 0.3s ease;
    outline: none;
    position: relative;
    overflow: hidden;
    background-color: #007bff;
    color: white;
}

.response-button:hover {
    background-color: #0056b3;
}

.response-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.button-text {
    position: relative;
    font-size: 1.5em;
    white-space: nowrap; /* 1行に抑える */
    z-index: 2;
}

/*-------------------------------------------
回答中のローディング画面
-------------------------------------------*/
.loading-spinner {
    border: 4px solid rgb(18, 136, 214);
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}


/*-------------------------------------------
ファイル名出力、コピーボタン
-------------------------------------------*/
.answer-container {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    width: 85%;
    
}

.answer-container textarea {
    flex-grow: 1;
    margin-right: 20px;
    text-align: center;
    resize: none; /* フレキシブルなリサイズを無効化 */
    position: relative;
}

.answer-container button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
}

.answer-container button:hover {
    background-color: #45a049;
}

/*-------------------------------------------
エラーハンドリング
-------------------------------------------*/
.error-message {
    margin-bottom: 10px;
    position: relative;
    margin:0px 5%;
}

.alert {
    padding: 15px;
    border-radius: 5px;
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

/*-------------------------------------------
Aboutセクションのスタイリング
-------------------------------------------*/
.about-container {
    margin-top: 100px;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.project {
    flex: 0 0 calc(33.333% - 10px); /* 3つのプロジェクトを等間隔に配置 */
    text-align: center;
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.project img {
    max-width: 30%;
    height: auto;
    margin-bottom: 10px;
}

.project h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.project p {
    font-size: 14px;
}

/* セクション間の余白 */
.about:not(:last-child) {
    margin-bottom: 40px;
}

/*-------------------------------------------
レスポンシブデザイン
-------------------------------------------*/
@media screen and (max-width: 768px) {
    .main-title {
        font-size: 2em;
        white-space: nowrap;
    }

    .fileinput-text {
        font-size: 0.9rem;
    }

    .large-input {
        font-size: 0.7rem;
    }

    .button-text {
        position: relative;
        font-size: 1em;
        white-space: nowrap;
    }

    .project {
        flex: 0 0 calc(50% - 10px);
    }
}

@media screen and (max-width: 480px) {
    .project {
        flex: 0 0 calc(100% - 10px);
    }
}

</style>