<template>
    <nav class="navbar" :class="{ 'fixed': isNavbarFixed }">
        <div class="container">
            <div class="logo-container" @click="goToHome">
                <img src="@/assets/pictures/yurutools_icon.webp" alt="Logo" class="logo-image" />
                <div class="company-name">ゆるTools</div>
            </div>
            <div class="nav-links">無料でシンプルなAIお助けツール</div>
            <div class="media-icons">
                <img src="@/assets/pictures/745_profile.webp" alt="プロフィール画像" class="profile-icons" @click="goToProfile">
                <a href="https://www.buymeacoffee.com/yurutools" target="_blank">
                    <img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" class="coffee-button">
                </a>
                <button class="about-button" @click="goToAbout" >About</button>
            </div>
        </div>
    </nav>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';

const isNavbarFixed = ref(false);
const router = useRouter();

// スクロール時の処理
const handleScroll = () => {
    const scrollPosition = window.scrollY;
    // ここで適切なスクロール位置を設定してください
    const scrollThreshold = 0;
    isNavbarFixed.value = scrollPosition > scrollThreshold;
    };

const goToHome = () => {
    router.push('/');
    };

const goToAbout = () => {
    router.push('/about');
    };

const goToProfile = () => {
    router.push('/profile');
};

    onMounted(() => {
    // スクロールイベントを追加
    window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
    // コンポーネントがアンマウントされる際にイベントを削除
    window.removeEventListener('scroll', handleScroll);
    });

</script>

<style scoped>
/*-------------------------------------------
各項目のスタイリング
-------------------------------------------*/
.navbar {
    max-width: 100%;
    height: 64px;
    background-color: #ddd;
    display: flex;
    align-items: center;
    z-index: 100;
}

.fixed {
    position: fixed;
    max-width: 100%;
    top: 0;
    left: 0;
    right: 0;
    /* カスタムスタイル */
    background-color: #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.container {
    width: 100%;
    display: flex;
    color: #000000;
    align-items: center;
    justify-content: space-between;
}

/*-------------------------------------------
ロゴ・タイトルのスタイル
-------------------------------------------*/
.logo-container {
    display: flex;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
}

.logo-image {
    width: 40px;
    height: 40px;
}

.company-name {
    font-size: 32px;
    color: #000000;
    margin-left: 10px;
    white-space: nowrap;
}

/*-------------------------------------------
文章・アイコン
-------------------------------------------*/
.nav-links {
    display: flex;
    list-style: none;
    font-size: 18px;
    white-space: nowrap; /* 1行に抑える */
    margin-left: auto;
}

.media-icons {
    margin-right: 10px;
    display: flex;
    align-items: left;
    justify-content: space-between;
}

.media-icons img,
.media-icons a,
.media-icons button {
    transition: transform 0.2s; /* ホバー効果のためのトランジション */
}

.media-icons img:hover,
.media-icons a:hover,
.media-icons button:hover {
    transform: scale(1.05); /* ホバー時に少し大きくする */
}

.profile-icons {
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%;
    object-fit: cover;
}

.coffee-button {
    top: 10px;
    height: 30px;
    width: auto;
    margin: 0 10px ;
}

.about-button {
    padding: 10px;
    background-color: #2a2a2a;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    white-space: nowrap;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.about-button:hover {
    background-color: #45a049;
}

/*-------------------------------------------
レスポンシブデザイン
-------------------------------------------*/
@media (max-width: 768px) {
    .logo-image {
        width: 30px;
        height: 30px;
    }

    .company-name {
        top: 3px;
        font-size:  1.2rem;
        color: #000000;
        margin-left: 10px;
        white-space: nowrap;
    }


    .nav-links {
        display: none; /* モバイル向けに非表示にする */
    }

    .profile-icons {
        top: 5px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        border-radius: 50%;
        object-fit: cover; 
    }

    .coffee-button {
        height: 20px;
        width: auto;
        margin: 0 10px ;
    }

    .about-button {
        top: 3px;
        font-size: 0.8rem;
}

}

</style>